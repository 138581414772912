import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';



@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // var winH = $(window).height(),
    //      navH = $('.navbar').innerHeight();
    //  $('.slider .carousel-item').height(winH - navH);
  }

}
