import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notfall',
  templateUrl: './notfall.component.html',
  styleUrls: ['./notfall.component.css']
})
export class NotfallComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
