import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { FooterComponent } from './components/footer/footer.component';
import { SpecialComponent } from './components/special/special.component';
import { NotfallComponent } from './components/notfall/notfall.component';
import { EmpfehlungenComponent } from './components/empfehlungen/empfehlungen.component';
import { WeSpeakComponent } from './components/we-speak/we-speak.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CarouselComponent,
    AboutComponent,
    ServicesComponent,
    FooterComponent,
    SpecialComponent,
    NotfallComponent,
    EmpfehlungenComponent,
    WeSpeakComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
