import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empfehlungen',
  templateUrl: './empfehlungen.component.html',
  styleUrls: ['./empfehlungen.component.css']
})
export class EmpfehlungenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
